import { getLeaveTypeApi } from './api'

export const state = {
  leaveTypes: [],
  isLeaveTypeLoaded: false,
}

export const getters = {
  leveTypes(state) {
    return state.leveTypes
  },
  leaveTypeOptions(state) {
    return state.leaveTypes
  },
  isLeaveTypeLoaded(state) {
    return state.isLeaveTypeLoaded
  },
}

export const mutations = {
  SET_LEAVE_TYPE(state, payload) {
    state.leaveTypes = payload
    state.isLeaveTypeLoaded = true
  },
  SET_LOADING(state, payload) {
    state.isLeaveTypeLoaded = payload
  },
}

export const actions = {
  init({ dispatch }) {},

  fetch({ commit }) {
    getLeaveTypeApi(true).then((data) =>
      commit('SET_LEAVE_TYPE', Object.freeze(data))
    )
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_LEAVE_TYPE', [])
    commit('SET_LOADING', false)
  },
}
