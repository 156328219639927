import api from '@api'
import SortBy from 'lodash/sortBy'
import { transformCategoryRecursive } from '@/src/data/category'
import { isLoggedIn } from '@utils/auth'

export function getCategoriesApi(moduleName, archived = false, params = {}) {
  return api
    .get(`${!isLoggedIn() ? '/public' : ''}/${moduleName}/category`, {
      params: {
        ...(archived ? { archived: true } : {}),
        ...params,
      },
      loader: false,
      notify: false,
    })
    .then((data) => {
      return transformCategoryRecursive(SortBy(data, (d) => d.node.objOrder))
    })
}
