import api from '@api'
import SortBy from 'lodash/sortBy'
import { transformPriority } from '@data/priority'
import { isLoggedIn } from '@utils/auth'

export function fetchPrioritiesApi(params) {
  return api
    .get(`${!isLoggedIn() ? '/public' : ''}/priority/list`, {
      loader: false,
      notify: false,
      params,
    })
    .then((data) => {
      const priorities = SortBy(data.objectList, 'objOrder')
      return priorities.map(transformPriority)
    })
}

export function getPrioritiesApi(params) {
  return api
    .post(
      `${!isLoggedIn() ? '/public' : ''}/priority/search/byqual`,
      {},
      {
        loader: false,
        notify: false,
        params,
      }
    )
    .then((data) => {
      const priorities = SortBy(data.objectList, 'objOrder')
      return priorities.map(transformPriority)
    })
}
