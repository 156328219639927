import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('changeType')

export const ChangeTypeComputed = {
  ...mapGetters({
    changeTypeOptions: 'changeTypeOptions',
    changeTypeColorsMap: 'changeTypeColorsMap',
    isChangeTypeLoaded: 'isChangeTypeLoaded',
  }),
}

export const ChangeTypeMethods = mapActions({
  fetchChangeTypes: 'fetch',
})
