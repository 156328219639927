import { isLoggedIn } from '@utils/auth'
import { getTaskTypes } from './api'

export const state = {
  task_types: [],
}

export const getters = {
  defaultTaskType(state) {
    return state.task_types.length ? state.task_types[0].id : undefined
  },
  taskTypeOptions(state) {
    const types = state.task_types
    return types.map((t) => ({ ...t, key: t.id, text: t.name }))
  },
}

export const mutations = {
  SET_TASK_TYPES(state, data) {
    state.task_types = data
  },
}

export const actions = {
  init({ dispatch }) {
    if (isLoggedIn()) {
      dispatch('fetch')
    }
  },

  fetch({ commit }, params) {
    return getTaskTypes(params).then((data) => {
      commit('SET_TASK_TYPES', data)
      return data
    })
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    // perform state clean up here when user is logged out
    commit('SET_TASK_TYPES', [])
  },
}
