import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('urgency')

export const UrgencyComputed = {
  ...mapGetters(['urgencyOptions']),
}

export const UrgencyMethods = {
  ...mapActions({
    fetchUrgency: 'fetch',
  }),
}
