import { getProjectTypeApi } from './api'

export const state = {
  projectTypes: [],
  isProjectTypeLoaded: false,
}

export const getters = {
  projectTypeOptions(state) {
    return state.projectTypes
  },
  isProjectTypeLoaded(state) {
    return state.isProjectTypeLoaded
  },
}

export const mutations = {
  SET_PROJECT_TYPE(state, payload) {
    state.projectTypes = payload
    state.isProjectTypeLoaded = true
  },
  SET_LOADING(state, payload) {
    state.isProjectTypeLoaded = payload
  },
}

export const actions = {
  init({ dispatch }) {},

  fetch({ commit }) {
    getProjectTypeApi(true).then((data) =>
      commit('SET_PROJECT_TYPE', Object.freeze(data))
    )
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_PROJECT_TYPE', [])
    commit('SET_LOADING', false)
  },
}
