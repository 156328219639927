import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('productType')

export const ProductTypeComputed = {
  ...mapGetters({
    productTypeOptions: 'productTypeOptions',
    isProductTypeLoaded: 'isProductTypeLoaded',
  }),
}

export const ProductTypeMethods = mapActions({
  fetchProductTypes: 'fetch',
})
