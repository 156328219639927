import modules from '@modules'

const moduleRoutes = modules.getModuleRoutes()

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/support-portal/home',
  },
  ...moduleRoutes,
  {
    path: '/report-chart-render',
    name: 'report-chart-render',
    component: require('@views/renderer/chart-render.vue').default,
    meta: {
      public: true,
      layout: 'LoginLayout',
    },
  },
  {
    path: '/dashboard-chart-render',
    name: 'dashboard-chart-render',
    component: require('@views/renderer/dashboard-chart-render.vue').default,
    meta: {
      public: true,
      layout: 'LoginLayout',
    },
  },
  {
    path: '/survey-response-chart-render',
    name: 'survey-response-chart-render',
    component: require('@views/renderer/survey-response-chart-render.vue')
      .default,
    meta: {
      public: true,
      layout: 'LoginLayout',
    },
  },
  {
    path: '/print-barcode',
    name: 'print-barcode',
    component: require('@views/renderer/print-barcode.vue').default,
    meta: {
      public: true,
      layout: 'LoginLayout',
    },
  },
  {
    path: '/print-qrcode',
    name: 'print-qrcode',
    component: require('@views/renderer/print-qrcode.vue').default,
    meta: {
      public: true,
      layout: 'LoginLayout',
    },
  },
  {
    path: '/upgrade',
    name: 'upgrade-plan',
    component: require('@views/errors/_404').default,
    meta: {
      public: true,
      layout: 'LoginLayout',
    },
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  {
    path: '/mobile-captcha-renderer',
    name: 'mobile-captcha-renderer',
    component: require('@views/renderer/mobile-captcha-renderer.vue').default,
    meta: {
      public: true,
      layout: 'LoginLayout',
    },
  },
  {
    path: '/oauth/callback',
    name: 'oauth-callback',
    component: require('@views/renderer/oauth-callback.vue').default,
    meta: {
      public: true,
      layout: 'LoginLayout',
    },
  },
  {
    path: '/500',
    name: '500',
    component: require('@views/errors/_500').default,
    meta: {
      public: true,
      layout: 'LoginLayout',
    },
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  {
    path: '/200',
    name: '200',
    component: require('@views/errors/_200').default,
    meta: {
      public: true,
      layout: 'LoginLayout',
    },
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/errors/_404').default,
    meta: {
      public: true,
      layout: 'LoginLayout',
    },
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
    meta: {
      public: true,
    },
  },
]

/** @TODO allow to register module dynamically run time later */

export default routes
