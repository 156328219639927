import mixins from './mixins'

let _Vue

export function install(Vue, options) {
  if (install.installed && _Vue === Vue) return
  install.installed = true

  _Vue = Vue

  Vue.prototype.__t = function(key, ...args) {
    if (this.$t && this.$modules) {
      return this.$t(
        `${this.$modules.getCurrentModule().getTranslationKey()}.${key}`,
        ...args
      )
    }
    return key
  }

  Vue.prototype.__tc = function(key, ...args) {
    if (this.$tc && this.$modules) {
      return this.$tc(
        `${this.$modules.getCurrentModule().getTranslationKey()}.${key}`,
        ...args
      )
    }
    return key
  }

  Vue.mixin(mixins)

  Object.defineProperty(Vue.prototype, '$modules', {
    get() {
      return this._modules
    },
  })
  Object.defineProperty(Vue.prototype, '$currentModule', {
    get() {
      return this._modules.getCurrentModule()
    },
  })
}
