import api from '@api'
import SortBy from 'lodash/sortBy'
import { transformTaskType } from '@/src/data/task'

export function getTaskTypes(params = {}) {
  return api
    .post(
      '/tasktype/search/byqual',
      {},
      {
        params: {
          offset: 0,
          size: 1000,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) =>
      SortBy(data.objectList || [], 'objOrder').map(transformTaskType)
    )
}
