import { fetchPrioritiesApi } from './api'
import { isLoggedIn } from '@utils/auth'

export const state = {
  priorities: [],
}

export const mutations = {
  SET_PRIORITIES(state, newValue) {
    state.priorities = newValue
  },
}

export const getters = {
  priorities(state) {
    return state.priorities
  },
  priorityOptions(state) {
    const priorities = state.priorities
    return priorities.map((p) => ({
      default: p.default,
      color: p.color,
      key: p.id,
      text: p.name,
    }))
  },
}

export const actions = {
  init({ dispatch }) {
    if (isLoggedIn()) {
      dispatch('fetch')
    }
  },

  fetch({ commit }, params) {
    return fetchPrioritiesApi(params).then((data) => {
      commit('SET_PRIORITIES', data)
    })
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_PRIORITIES', [])
  },
}
