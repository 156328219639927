import api from '@api'

export function getBusinessServiceApi(archived = false) {
  return api
    .post(
      `/businessservice/search/byqual`,
      {},
      {
        params: {
          ...(archived ? { archived: true } : {}),
          offset: 0,
          size: 1000,
        },
        notify: false,
      }
    )
    .then((data) => {
      return (data.objectList || []).map((s) => ({
        key: s.id,
        id: s.id,
        value: s.id,
        text: s.name,
        label: s.name,
        archived: s.removed,
      }))
    })
}
