/**
 * @class ModuleManager
 * @typedef {import('./base-module').default} ModuleDefinition
 */
class ModuleManager {
  _currentModule = null

  _availableModules = []

  _router = null

  _store = null

  moduleLoaderFn = (moduleName) =>
    Promise.reject(
      new Error('Please provide default moduleLoader function as a option')
    )

  /**
   * @constructor
   * @param {Array<ModuleDefinition>} modules
   * @param {Object} configs
   */
  constructor(modules, configs = {}) {
    if (configs.router) {
      this.setRouter(configs.router)
    }
    if (configs.store) {
      this.setStore(configs.store)
    }
    if (configs.moduleLoader) {
      this.moduleLoaderFn = configs.moduleLoader
    }
    ;(modules || []).forEach(this.addModule.bind(this))
    this._currentModule =
      (modules || []).length && modules[0] ? modules[0].getName() : ''
  }

  /**
   * Vue router setter
   * @param {VueRouter} router
   */
  setRouter(router) {
    this._router = router
  }

  /**
   * Vuex Store setter
   * @param {Vuex} store
   */
  setStore(store) {
    this._store = store
  }

  /**
   * Vue router getter
   * @returns {VueRouter}
   */
  getRouter() {
    return this._router
  }

  /**
   * Vuex Store getter
   * @param {Vuex}
   */
  getStore() {
    return this._store
  }

  /**
   * Set currently active module
   * @param {string} moduleName
   */
  setCurrentModule(moduleName) {
    this._currentModule = moduleName
  }

  /**
   * Get currently active module
   * @returns {ModuleDefinition}
   */
  getCurrent() {
    return this.getCurrentModule()
  }

  /**
   * Get currently active module
   * @returns {ModuleDefinition}
   */
  getCurrentModule() {
    return this.getModule(this._currentModule)
  }

  /**
   * @returns object
   */
  getModuleConfig() {
    const m = this.getCurrentModule()
    if (m) {
      m.getConfig()
    }
    return {}
  }

  /**
   * Get specific module by name of the module
   * @param {string} moduleName
   * @returns {import('./base-module')}
   */
  getModule(moduleName) {
    const moduleDefinition = this._availableModules.find(
      (moduleDefinition) =>
        moduleDefinition.name.toLowerCase() === moduleName.toLowerCase()
    )
    if (moduleDefinition) {
      return moduleDefinition
    }
    if (moduleName.length > 0) {
      throw new Error(`module with name ${moduleName} is not found`)
    }
  }

  /**
   * Check if module is registered
   * @param {string} moduleName
   * @returns boolean
   */
  hasModule(moduleName) {
    try {
      const m = this.getModule(moduleName)
      if (m) {
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }

  /**
   * Get all modules and get routes of each module
   * @returns array
   */
  getModuleRoutes() {
    let availableRoutes = []
    this._availableModules.forEach((moduleDefinition) => {
      if (moduleDefinition.getRoutes()) {
        availableRoutes = availableRoutes.concat(moduleDefinition.getRoutes())
      }
    })
    return availableRoutes
  }

  /**
   * Returns the route of module
   * @param {string|null} moduleName
   * @param {string} routeName
   * @param {object} routeArgs
   */
  getModuleRoute(moduleName = null, routeName, routeArgs = {}) {
    let moduleDefination
    if (moduleName) {
      moduleDefination = this.getModule(moduleName)
    } else {
      moduleDefination = this.getCurrentModule()
    }
    if (!moduleDefination) {
      return {}
    }
    return {
      name: `${moduleDefination.getRouteNamePrefix()}${
        routeName ? `.${routeName}` : ''
      }`,
      ...routeArgs,
    }
  }

  /**
   * Load module dynamic to the application
   * @param {Promise<ModuleDefinition>} loaderFn
   * @param {object} moduleOptions
   */
  loadModule(moduleName, moduleOptions) {
    return new Promise((resolve, reject) => {
      this.moduleLoaderFn(moduleName)
        .then((moduleDefination) => {
          this.addModule(
            new (moduleDefination.default || moduleDefination)(moduleOptions)
          )
          resolve()
        })
        .catch(reject)
    })
  }

  /**
   * Add module to the system
   * @param {ModuleDefinition} moduleDef
   */
  addModule(moduleDef) {
    if (this.hasModule(moduleDef.getName())) {
      return
    }
    this._availableModules.push(moduleDef)
    if (this._router) {
      this._router.addRoutes(moduleDef.getRoutes())
    }
  }
}

export default ModuleManager
