import { getContractTypeApi } from './api'

export const state = {
  contractTypes: [],
  isContractTypesLoaded: false,
}

export const getters = {
  contractTypeOptions(state) {
    return state.contractTypes
  },
  isContractTypesLoaded(state) {
    return state.isContractTypesLoaded
  },
}

export const mutations = {
  SET_CONTRACT_TYPE(state, payload) {
    state.contractTypes = payload
    state.isContractTypesLoaded = true
  },
  SET_LOADING(state, payload) {
    state.isContractTypesLoaded = payload
  },
}

export const actions = {
  init({ dispatch }) {},

  fetch({ commit }) {
    getContractTypeApi(true).then((data) =>
      commit('SET_CONTRACT_TYPE', Object.freeze(data))
    )
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_CONTRACT_TYPE', [])
    commit('SET_LOADING', false)
  },
}
