import { getSsoConfigApi } from './api'
export const state = {
  ssoConfig: {},
}

export const mutations = {
  SET_SSO_CONFIG(state, newValue) {
    state.ssoConfig = newValue
  },
}

export const getters = {
  ssoConfig(state) {
    return state.ssoConfig
  },
}

export const actions = {
  init({ dispatch }) {
    dispatch('fetch')
  },

  fetch({ commit }, params) {
    return getSsoConfigApi(true).then((data) => {
      commit('SET_SSO_CONFIG', data)
      return data
    })
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_SSO_CONFIG', {})
  },
}
