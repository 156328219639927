import api from '@api'
import SortBy from 'lodash/sortBy'
import { transformAssetTypeRecursive } from '@data/asset-type'
import { isLoggedIn } from '@utils/auth'

export function getAssetsApi(archived = false) {
  return api
    .get(`${!isLoggedIn() ? '/public' : ''}/asset/assettype`, {
      params: {
        ...(archived ? { archived: true } : {}),
      },
      loader: false,
      notify: false,
    })
    .then((data) => {
      return transformAssetTypeRecursive(SortBy(data, (d) => d.node.objOrder))
    })
}
