import api from '@api'
import SortBy from 'lodash/sortBy'
import { transformImpact } from '@data/impact'
import { isLoggedIn } from '@utils/auth'

export function fetchImpactsApi(params) {
  return api
    .get(`${!isLoggedIn() ? '/public' : ''}/impact/list`, {
      notify: false,
      params,
    })
    .then((data) => {
      const impact = SortBy(data.objectList || [], 'objOrder')
      return impact.map(transformImpact)
    })
}

export function getImpactsApi(params) {
  return api
    .post(
      `${!isLoggedIn() ? '/public' : ''}/impact/search/byqual`,
      {},
      {
        notify: false,
        params,
      }
    )
    .then((data) => {
      const impact = SortBy(data.objectList || [], 'objOrder')
      return impact.map(transformImpact)
    })
}
