import { isLoggedIn } from '@utils/auth'
import { fetchSoftwareTypeApi } from './api'
import Constants from '@constants'
export const state = {
  softwareTypes: [],
}

export const getters = {
  softwareTypes(state) {
    return state.softwareTypes
  },
  softwareTypeOptions(state) {
    const softwareTypes = state.softwareTypes
    return softwareTypes.map((p) => ({
      key: p.id,
      id: p.id,
      value: p.id,
      text: p.name,
      label: p.name,
      systemName: p.systemName,
      archived: p.archived,
    }))
  },
}

export const mutations = {
  SET_SOFTWARE_TYPES(state, data) {
    state.softwareTypes = data
  },
}

export const actions = {
  init({ dispatch }) {
    if (isLoggedIn()) {
      dispatch('fetch')
    }
  },

  fetch({ commit, rootGetters }, params) {
    const availableModules = rootGetters['license/availableModules']
    if (availableModules.indexOf(Constants.ASSET) >= 0) {
      return fetchSoftwareTypeApi({ ...(params || {}), archived: true }).then(
        (data) => {
          commit('SET_SOFTWARE_TYPES', data)
          return data
        }
      )
    }
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_SOFTWARE_TYPES', [])
  },
}
