import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('releaseRiskType')

export const ReleaseRiskTypeComputed = {
  ...mapGetters({
    releaseReleaseRiskTypeOptions: 'releaseReleaseRiskTypeOptions',
    isReleaseRiskTypeLoaded: 'isReleaseRiskTypeLoaded',
  }),
}

export const ReleaseRiskTypeMethods = mapActions({
  fetchReleaseRiskTypes: 'fetch',
})
