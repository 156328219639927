import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('location')
const { mapGetters: locationConfigGetters, mapActions: locationConfigActions } =
  createNamespacedHelpers('location/locationConfig')

export const LocationComputed = {
  ...mapGetters(['locationOptions']),
  ...locationConfigGetters({
    locationConfig: 'locationConfig',
    reportHierarchy: 'reportHierarchy',
    requestOnlyLeafNodeSelectable: 'allowedRequestLeafNode',
    problemOnlyLeafNodeSelectable: 'allowedProblemLeafNode',
    changeOnlyLeafNodeSelectable: 'allowedChangeLeafNode',
    releaseOnlyLeafNodeSelectable: 'allowedReleaseLeafNode',
  }),
}

export const LocationMethods = {
  ...mapActions({
    fetchLocation: 'fetch',
  }),
  ...locationConfigActions({
    locationConfigFetch: 'fetchConfig',
  }),
}
