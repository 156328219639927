import api from '@api'
import {
  transformPreference,
  transformPreferenceForServer,
  transformTenantPreference,
  transformTenantPreferenceForServer,
  // transformTenantPreferencesForServer,
} from '@src/data/preference'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import { isLoggedIn } from '@utils/auth'

const __t = getRootTranslator()
const __tc = getRootPluaralTranslator()

export function getPreferenceApi() {
  return api.get('user/preference').then((data) => transformPreference(data))
}

export function getTenantPreferenceApi(params) {
  return api
    .get(`${!isLoggedIn() ? '/public' : ''}/tenantpreference`, { params })
    .then((data) => transformTenantPreference(data))
}

export function updateTenantPreferenceApi(preference) {
  return api
    .patch('tenantpreference', transformTenantPreferenceForServer(preference), {
      message: __t('saved_successfully', { resource: __tc('preference') }),
    })
    .then((data) => transformPreference(data))
}

export function updatePreferenceApi(preference) {
  return api
    .patch('user/preference', transformPreferenceForServer(preference), {
      message: __t('saved_successfully', { resource: __tc('preference') }),
    })
    .then((data) => transformPreference(data))
}
