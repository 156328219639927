import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('releaseReasonType')

export const ReleaseReasonTypeComputed = {
  ...mapGetters({
    releaseReasonTypeOptions: 'releaseReasonTypeOptions',
    isReleaseReasonTypeLoaded: 'isReleaseReasonTypeLoaded',
  }),
}

export const ReleaseReasonTypeMethods = mapActions({
  fetchReleaseReasonTypes: 'fetch',
})
