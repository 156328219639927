import { fetchUserGroupsApi } from './api'
import { isLoggedIn } from '@utils/auth'
import { getRootPluaralTranslator } from '@utils/get-module-translator'
const __tc = getRootPluaralTranslator()

function getGroupModule(moduleName) {
  const vuexModule = {
    namespaced: true,
  }
  vuexModule.state = {
    groups: [],
  }

  vuexModule.getters = {
    groups(state) {
      return state.groups
    },
    groupsAsOptions(state) {
      return [
        {
          key: 0,
          id: 0,
          name: __tc('unassigned'),
        },
      ].concat(
        state.groups.map((p) => ({
          key: p.id,
          text: p.name,
        }))
      )
    },
    getGroupById(state) {
      return (groupId) => {
        return state.groups.find(({ id }) => id === groupId)
      }
    },
  }

  vuexModule.mutations = {
    SET_GROUPS(state, newValue) {
      state.groups = newValue
    },
  }

  vuexModule.actions = {
    init({ dispatch }) {
      if (isLoggedIn()) {
        dispatch('fetch')
      }
    },

    fetch({ commit }, params) {
      return fetchUserGroupsApi(moduleName, params).then((data) => {
        commit('SET_GROUPS', Object.freeze(data))
      })
    },

    /**
     * destroy all states when user is logged out
     */
    destroy({ commit }) {
      commit('SET_GROUPS', [])
    },
  }
  return vuexModule
}

export const modules = {
  requester: getGroupModule('requester'),
  technician: getGroupModule('technician'),
}
