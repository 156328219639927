import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('projectType')

export const ProjectTypeComputed = {
  ...mapGetters({
    projectTypeOptions: 'projectTypeOptions',
    isProjectTypeLoaded: 'isProjectTypeLoaded',
  }),
}

export const ProjectTypeMethods = mapActions({
  fetchProjectTypes: 'fetch',
})
