import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers(
  'supportPortalConfig'
)

export const SupportPortalConfigComputed = {
  ...mapGetters({
    allowRegistration: 'allowRegistration',
    allowGuestToCreateIncident: 'allowGuestToCreateIncident',
    allowGuestToRequestForService: 'allowGuestToRequestForService',
    showRelatedKnowledge: 'showRelatedKnowledge',
    allowToLinkAsset: 'allowToLinkAsset',
    allowToLinkCi: 'allowToLinkCi',
    allowToReopenClosedReq: 'allowToReopenClosedReq',
    allowToCloseReq: 'allowToCloseReq',
    closedGraceTimeType: 'closedGraceTimeType',
    closedGraceTime: 'closedGraceTime',
    allowToReopenResolvedReq: 'allowToReopenResolvedReq',
    resolvedGraceTimeType: 'resolvedGraceTimeType',
    resolvedGraceTime: 'resolvedGraceTime',
    allowReqToAccessMyAsset: 'allowReqToAccessMyAsset',
    allowReqToAccessMyCi: 'allowReqToAccessMyCi',
    allowRequesterToViewBarcodeAndQRCode:
      'allowRequesterToViewBarcodeAndQRCode',
    allowRequesterToAccessKB: 'allowRequesterToAccessKB',
    allowRequesterToAccessServiceRequest:
      'allowRequesterToAccessServiceRequest',
    allowRequesterToAccessMyApprovals: 'allowRequesterToAccessMyApprovals',
    allowReqToAccessMyChanges: 'allowReqToAccessMyChanges',
    allowRequesterToCreateRequestBehalfOfOtherRequester:
      'allowRequesterToCreateRequestBehalfOfOtherRequester',
    allowRequesterToSubmitFeedback: 'allowRequesterToSubmitFeedback',
    allowRequesterToAccestTicketApprovalTab:
      'allowRequesterToAccestTicketApprovalTab',
    selfServiceSetting: 'selfServiceSetting',
    allowRequesterToCreateIncidents: 'allowRequesterToCreateIncidents',
    allowRequesterToCreateIncidentBehalfOfOtherRequester:
      'allowRequesterToCreateIncidentBehalfOfOtherRequester',
    allowRequesterToViewAuditTrail: 'allowRequesterToViewAuditTrail',
    mandateCommentToReopenRequest: 'mandateCommentToReopenRequest',
  }),
}

export const SupportPortalConfigMethods = mapActions({
  fetchConfigs: 'fetch',
})
