import { createNamespacedHelpers } from 'vuex'

const { mapState, mapGetters, mapActions } =
  createNamespacedHelpers('leaveType')

export const LeaveTypeComputed = {
  ...mapState({
    leaveTypes: (state) => state.leaveTypes,
  }),
  ...mapGetters({
    leaveTypeOptions: 'leaveTypeOptions',
    isLeaveTypeLoaded: 'isLeaveTypeLoaded',
  }),
}

export const LeaveTypeMethods = mapActions({
  fetchLeaveTypes: 'fetch',
})
