import { createNamespacedHelpers } from 'vuex'
import Constants from '@constants'

const { mapGetters: requestGetters, mapActions: requestActions } =
  createNamespacedHelpers(`customRules/${Constants.REQUEST}`)
const { mapGetters: serviceCatalogGetters, mapActions: serviceCatalogActions } =
  createNamespacedHelpers(`customRules/${Constants.SERVICE_CATALOG}`)
const { mapGetters: problemGetters, mapActions: problemActions } =
  createNamespacedHelpers(`customRules/${Constants.PROBLEM}`)
const { mapGetters: changeGetters, mapActions: changeActions } =
  createNamespacedHelpers(`customRules/${Constants.CHANGE}`)
const { mapGetters: releaseGetters, mapActions: releaseActions } =
  createNamespacedHelpers(`customRules/${Constants.RELEASE}`)
const { mapGetters: purchaseGetters, mapActions: purchaseActions } =
  createNamespacedHelpers(`customRules/${Constants.PURCHASE}`)
const { mapGetters: projectGetters, mapActions: projectActions } =
  createNamespacedHelpers(`customRules/${Constants.PROJECT}`)
const { mapGetters: assetGetters, mapActions: assetActions } =
  createNamespacedHelpers(`customRules/${Constants.ASSET}`)

export const CustomRulesComputed = {
  ...requestGetters({
    requestNoteRequiredFields: 'fields',
    requestIsFieldRequiredNote: 'fieldRequiresNote',
    requestDialogFields: 'dialogFields',
    requestIsFieldDialog: 'fieldDialog',
    requestWorklogAccess: 'worklogAccess',
  }),
  ...serviceCatalogGetters({
    service_catalogNoteRequiredFields: 'fields',
    service_catalogIsFieldRequiredNote: 'fieldRequiresNote',
    service_catalogDialogFields: 'dialogFields',
    service_catalogIsFieldDialog: 'fieldDialog',
    service_catalogWorklogAccess: 'worklogAccess',
  }),
  ...problemGetters({
    problemNoteRequiredFields: 'fields',
    problemIsFieldRequiredNote: 'fieldRequiresNote',
    problemDialogFields: 'dialogFields',
    problemIsFieldDialog: 'fieldDialog',
    problemWorklogAccess: 'worklogAccess',
  }),
  ...changeGetters({
    changeNoteRequiredFields: 'fields',
    changeIsFieldRequiredNote: 'fieldRequiresNote',
    changeDialogFields: 'dialogFields',
    changeIsFieldDialog: 'fieldDialog',
    changeWorklogAccess: 'worklogAccess',
  }),
  ...releaseGetters({
    releaseNoteRequiredFields: 'fields',
    releaseIsFieldRequiredNote: 'fieldRequiresNote',
    releaseDialogFields: 'dialogFields',
    releaseIsFieldDialog: 'fieldDialog',
    releaseWorklogAccess: 'worklogAccess',
  }),
  ...purchaseGetters({
    purchaseNoteRequiredFields: 'fields',
    purchaseIsFieldRequiredNote: 'fieldRequiresNote',
    purchaseDialogFields: 'dialogFields',
    purchaseIsFieldDialog: 'fieldDialog',
  }),
  ...projectGetters({
    projectNoteRequiredFields: 'fields',
    projectIsFieldRequiredNote: 'fieldRequiresNote',
    projectDialogFields: 'dialogFields',
    projectIsFieldDialog: 'fieldDialog',
    projectWorklogAccess: 'worklogAccess',
  }),
  ...assetGetters({
    asset_hardwareAskForApproval: 'assetHardwareAskForApproval',
    asset_non_itAskForApproval: 'assetNonItAskForApproval',
  }),
}

export const CustomRulesMethods = {
  ...requestActions({
    requestFetchNoteRules: 'fetch',
    requestFetchDialogRules: 'fetch',
    requestFetchWorklogRules: 'fetch',
  }),
  ...serviceCatalogActions({
    service_catalogFetchNoteRules: 'fetch',
    service_catalogFetchDialogRules: 'fetch',
    service_catalogFetchWorklogRules: 'fetch',
  }),
  ...problemActions({
    problemFetchNoteRules: 'fetch',
    problemFetchDialogRules: 'fetch',
    problemFetchWorklogRules: 'fetch',
  }),
  ...changeActions({
    changeFetchNoteRules: 'fetch',
    changeFetchDialogRules: 'fetch',
    changeFetchWorklogRules: 'fetch',
  }),
  ...releaseActions({
    releaseFetchNoteRules: 'fetch',
    releaseFetchDialogRules: 'fetch',
    releaseFetchWorklogRules: 'fetch',
  }),
  ...purchaseActions({
    purchaseFetchNoteRules: 'fetch',
    purchaseFetchDialogRules: 'fetch',
  }),
  ...projectActions({
    projectFetchNoteRules: 'fetch',
    projectFetchDialogRules: 'fetch',
    projectFetchWorklogRules: 'fetch',
  }),
  ...assetActions({
    assetFetchManualUpdateRules: 'fetch',
  }),
}
