import { getMspApi } from './api'
import { isLoggedIn } from '@utils/auth'
export const state = {
  config: {},
}

export const getters = {
  enableMsp(state) {
    return state.config.enableMsp
  },
}

export const mutations = {
  SET_CONFIG(state, payload) {
    state.config = payload
  },
}

export const actions = {
  init({ dispatch }) {
    if (isLoggedIn()) {
      dispatch('fetch')
    }
  },

  fetch({ commit }) {
    return getMspApi().then((data) => {
      commit('SET_CONFIG', data)
      return data
    })
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    // perform state clean up here when user is logged out
  },
}
