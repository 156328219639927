import api from '@api'
import { transformUserGroup } from '@data/user-group'
import { isLoggedIn } from '@utils/auth'

export function fetchUserGroupsApi(type, params = {}) {
  return api
    .get(`${!isLoggedIn() ? '/public' : ''}/${type}/group/list`, {
      params: {
        ...params,
      },
      loader: false,
      notify: false,
    })
    .then(({ objectList }) => {
      return (objectList || []).map(transformUserGroup)
    })
}

export function getUserGroupsApi(
  type,
  archived = false,
  offset,
  limit,
  params = {}
) {
  return api
    .post(
      `${!isLoggedIn() ? '/public' : ''}/${type}/group/search/byqual`,
      {},
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          archived,
          ...params,
        },
        loader: false,
        notify: false,
      }
    )
    .then(({ objectList }) => {
      return (objectList || []).map(transformUserGroup)
    })
}
