import { isDef } from './utils'
import Manager from './manager'

export default {
  beforeCreate() {
    const moduleManager = isDef(this.$options.modules)
      ? this.$options.modules
      : isDef(this.$root.$modules) && this.$root.$modules

    if (moduleManager instanceof Manager) {
      this._modules = this.$options.modules || this.$root.$modules
      if (this.$route) {
        if (
          Array.isArray(this.$route.matched) &&
          this.$route.matched.length > 0
        ) {
          if (this.$route.matched && this.$route.matched.length) {
            let moduleName
            this.$route.matched.forEach((m) => {
              if (m.meta && m.meta.moduleName) {
                moduleName = m.meta.moduleName
              }
            })
            if (moduleName) {
              this._modules.setCurrentModule(moduleName)
            }
          }
        }
        // define navigation helpers for modules
        this._modules.navigate = (moduleName, path, args) => {
          this.$router.push(
            this._modules.getModuleRoute(moduleName, path, args)
          )
        }
        this._modules.replace = (moduleName, path, args) => {
          this.$router.replace(
            this._modules.getModuleRoute(moduleName, path, args)
          )
        }
      }
    } else {
      // console.warn('Please Add modules in the root view instance')
    }
  },
}
