import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('businessService')

export const BusinessServiceComputed = {
  ...mapGetters({
    businessServiceOptions: 'businessServiceOptions',
    isBusinessServiceLoaded: 'isBusinessServiceLoaded',
  }),
}

export const BusinessServiceMethods = mapActions({
  fetchBusinessServices: 'fetch',
})
