import { getFormApi as baseFormApi } from '@modules/form/form-api'
import { flattenFields } from '@data/form'

export function getFormApi(moduleName, isPublic, params) {
  return baseFormApi(moduleName, undefined, params, isPublic).then((form) => {
    return {
      ...form,
      fields: flattenFields(form.fields),
    }
  })
}
