import { createNamespacedHelpers } from 'vuex'
import Constants from '@constants'

const { mapGetters: requestGetters, mapActions: requestActions } =
  createNamespacedHelpers(`keyword/${Constants.REQUEST}`)
const { mapGetters: problemGetters, mapActions: problemActions } =
  createNamespacedHelpers(`keyword/${Constants.PROBLEM}`)
const { mapGetters: changeGetters, mapActions: changeActions } =
  createNamespacedHelpers(`keyword/${Constants.CHANGE}`)
const { mapGetters: releaseGetters, mapActions: releaseActions } =
  createNamespacedHelpers(`keyword/${Constants.RELEASE}`)
const { mapGetters: assetGetters, mapActions: assetActions } =
  createNamespacedHelpers(`keyword/${Constants.ASSET}`)
const { mapGetters: cmdbGetters, mapActions: cmdbActions } =
  createNamespacedHelpers(`keyword/${Constants.CMDB}`)
const { mapGetters: assetHardwareGetters, mapActions: assetHardwareActions } =
  createNamespacedHelpers(`keyword/${Constants.ASSET_HARDWARE}`)
const { mapGetters: assetSoftwareGetters, mapActions: assetSoftwareActions } =
  createNamespacedHelpers(`keyword/${Constants.ASSET_SOFTWARE}`)
const { mapGetters: assetNonItGetters, mapActions: assetNonItActions } =
  createNamespacedHelpers(`keyword/${Constants.ASSET_NON_IT}`)
const {
  mapGetters: assetConsumableGetters,
  mapActions: assetConsumableActions,
} = createNamespacedHelpers(`keyword/${Constants.ASSET_CONSUMABLE}`)
const { mapGetters: assetMovementGetters, mapActions: assetMovementActions } =
  createNamespacedHelpers(`keyword/${Constants.ASSET_MOVEMENT}`)

const { mapGetters: contractGetters, mapActions: contractActions } =
  createNamespacedHelpers(`keyword/${Constants.CONTRACT}`)
const { mapGetters: purchaseGetters, mapActions: purchaseActions } =
  createNamespacedHelpers(`keyword/${Constants.PURCHASE}`)
const { mapGetters: taskGetters, mapActions: taskActions } =
  createNamespacedHelpers(`keyword/${Constants.TASK}`)
const { mapGetters: userGetters, mapActions: userActions } =
  createNamespacedHelpers(`keyword/${Constants.USER}`)
const { mapGetters: userLeaveGetters, mapActions: userLeaveActions } =
  createNamespacedHelpers(`keyword/${Constants.USER_LEAVE}`)
const {
  mapGetters: softwareLicenseGetters,
  mapActions: softwareLicenseActions,
} = createNamespacedHelpers(`keyword/${Constants.SOFTWARE_LICENSE}`)
const { mapGetters: knowledgeGetters, mapActions: knowledgeActions } =
  createNamespacedHelpers(`keyword/${Constants.KNOWLEDGE}`)
const { mapGetters: userSurveyGetters, mapActions: userSurveyActions } =
  createNamespacedHelpers(`keyword/${Constants.USER_SURVEY}`)
const { mapGetters: projectGetters, mapActions: projectActions } =
  createNamespacedHelpers(`keyword/${Constants.PROJECT}`)
const {
  mapGetters: autoTicketCreateGetters,
  mapActions: autoTicketCreateActions,
} = createNamespacedHelpers(`keyword/${Constants.AUTO_TICKET_CREATE}`)
const { mapGetters: autoTaskCreateGetters, mapActions: autoTaskCreateActions } =
  createNamespacedHelpers(`keyword/${Constants.AUTO_TASK_CREATE}`)
const {
  mapGetters: assetFieldTransitionHistoryGetters,
  mapActions: assetFieldTransitionHistoryActions,
} = createNamespacedHelpers(
  `keyword/${Constants.ASSET_FIELD_TRANSITION_HISTORY}`
)
const {
  mapGetters: ciFieldTransitionHistoryGetters,
  mapActions: ciFieldTransitionHistoryActions,
} = createNamespacedHelpers(`keyword/${Constants.CI_FIELD_TRANSITION_HISTORY}`)

export const KeywordComputed = {
  ...requestGetters({
    request: 'keywords',
  }),
  ...problemGetters({
    problem: 'keywords',
  }),
  ...changeGetters({
    change: 'keywords',
  }),
  ...releaseGetters({
    release: 'keywords',
  }),
  ...assetGetters({
    asset: 'keywords',
  }),
  ...cmdbGetters({
    cmdb: 'keywords',
  }),
  ...assetHardwareGetters({
    asset_hardware: 'keywords',
  }),
  ...assetSoftwareGetters({
    asset_software: 'keywords',
  }),
  ...assetNonItGetters({
    asset_non_it: 'keywords',
  }),
  ...assetConsumableGetters({
    asset_consumable: 'keywords',
  }),
  ...assetMovementGetters({
    asset_movement: 'keywords',
  }),
  ...contractGetters({
    contract: 'keywords',
  }),
  ...purchaseGetters({
    purchase: 'keywords',
  }),
  ...taskGetters({
    task: 'keywords',
  }),
  ...userGetters({
    user: 'keywords',
  }),
  ...userLeaveGetters({
    user_leave: 'keywords',
  }),
  ...softwareLicenseGetters({
    software_license: 'keywords',
  }),
  ...knowledgeGetters({
    knowledge: 'keywords',
  }),
  ...userSurveyGetters({
    user_survey: 'keywords',
  }),
  ...projectGetters({
    project: 'keywords',
  }),
  ...autoTicketCreateGetters({
    auto_ticket_create: 'keywords',
  }),
  ...autoTaskCreateGetters({
    auto_task_create: 'keywords',
  }),
  ...assetFieldTransitionHistoryGetters({
    asset_field_transition_history: 'keywords',
  }),
  ...ciFieldTransitionHistoryGetters({
    ci_field_transition_history: 'keywords',
  }),
}

export const KeywordMethods = {
  ...requestActions({
    requestKeywords: 'fetch',
    responseTemplateKeywords: 'fetchResponseTemplateKeywords',
  }),
  ...problemActions({
    problemKeywords: 'fetch',
  }),
  ...changeActions({
    changeKeywords: 'fetch',
  }),
  ...releaseActions({
    releaseKeywords: 'fetch',
  }),
  ...assetActions({
    assetKeywords: 'fetch',
  }),
  ...cmdbActions({
    cmdbKeywords: 'fetch',
  }),
  ...assetHardwareActions({
    asset_hardwareKeywords: 'fetch',
  }),
  ...assetSoftwareActions({
    asset_softwareKeywords: 'fetch',
  }),
  ...assetNonItActions({
    asset_non_itKeywords: 'fetch',
  }),
  ...assetConsumableActions({
    asset_consumableKeywords: 'fetch',
  }),
  ...assetMovementActions({
    asset_movementKeywords: 'fetch',
  }),
  ...contractActions({
    contractKeywords: 'fetch',
  }),
  ...purchaseActions({
    purchaseKeywords: 'fetch',
  }),
  ...taskActions({
    taskKeywords: 'fetch',
  }),
  ...userActions({
    userKeywords: 'fetch',
  }),
  ...userLeaveActions({
    user_leaveKeywords: 'fetch',
  }),
  ...softwareLicenseActions({
    software_licenseKeywords: 'fetch',
  }),
  ...knowledgeActions({
    knowledgeKeywords: 'fetch',
  }),
  ...userSurveyActions({
    user_surveyKeywords: 'fetch',
  }),
  ...projectActions({
    projectKeywords: 'fetch',
    projecteTemplateKeywords: 'fetchResponseTemplateKeywords',
  }),
  ...autoTicketCreateActions({
    auto_ticket_createKeywords: 'fetch',
  }),
  ...autoTaskCreateActions({
    auto_task_createKeywords: 'fetch',
  }),
  ...assetFieldTransitionHistoryActions({
    asset_field_transition_historyKeywords: 'fetch',
  }),
  ...ciFieldTransitionHistoryActions({
    ci_field_transition_historyKeywords: 'fetch',
  }),
}
