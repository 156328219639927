import { createNamespacedHelpers } from 'vuex'

const { mapGetters: ciTypeGetters, mapActions: ciTypeActions } =
  createNamespacedHelpers('ciType')

export const CITypeComputed = {
  ...ciTypeGetters({
    ciTypes: 'ciTypes',
  }),
}

export const CITypeMethods = {
  ...ciTypeActions({
    fetchCIType: 'fetch',
  }),
}
