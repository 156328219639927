import { createNamespacedHelpers } from 'vuex'
import Constants from '@constants'

const {
  mapGetters: requestGetters,
  mapActions: requestActions,
} = createNamespacedHelpers(`fieldMapping/${Constants.REQUEST}`)

export const FieldMappingComputed = {
  ...requestGetters({
    requestFieldMapping: 'fieldMapping',
  }),
}

export const FieldMappingMethods = {
  ...requestActions({
    requestFieldMappingFetch: 'fetch',
  }),
}
