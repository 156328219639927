import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('technician')

export const TechnicianComputed = {
  ...mapGetters(['technicianOptions', 'technician']),
}

export const TechnicianMethods = mapActions({
  fetchTechnicians: 'fetch',
})
