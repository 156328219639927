/**
 * @class
 */
class BaseModule {
  /**
   * @constructor
   * @param {string} name
   * @param {[key: string]: string} configs
   */
  constructor(name, configs) {
    this.name = name
    this.config = configs || {}
  }

  /** @property Array<object> */
  routes = []

  /**
   * @returns {string}
   */
  getName() {
    return this.name
  }

  /**
   * @returns {string}
   */
  getTranslationKey() {
    return this.translationKey
  }

  /**
   * @returns {array}
   */
  getDependentTranslationKeys() {
    return this.config.dependentTranslationKeys
  }

  /**
   * @returns {{[key: string]: string}}
   */
  getConfig() {
    return this.config
  }

  /**
   * @returns {string}
   */
  getRoutePrefix() {
    return this.config.routePrefix
  }

  /**
   * @returns {string}
   */
  getRouteNamePrefix() {
    return this.config.routeNamePrefix
  }

  /**
   * @returns {Array<object>}
   */
  getRoutes() {
    if (this.getConfig().directRoutesAvailable !== false) {
      return this.routes
    }
  }

  getRoute(routeName, routeArgs = {}) {
    return {
      name: `${this.getRouteNamePrefix()}${routeName ? `.${routeName}` : ''}`,
      ...routeArgs,
    }
  }
}

export default BaseModule
