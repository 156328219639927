import api from '@api'
import SortBy from 'lodash/sortBy'
import { transformUrgency } from '@data/urgency'
import { isLoggedIn } from '@utils/auth'

export function getUrgenciesApi(params) {
  return api
    .post(
      `${!isLoggedIn() ? '/public' : ''}/urgency/search/byqual`,
      {},
      {
        notify: false,
        params,
      }
    )
    .then((data) => {
      const urgency = SortBy(data.objectList || [], 'objOrder')
      return urgency.map(transformUrgency)
    })
}
