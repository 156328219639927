import { createNamespacedHelpers } from 'vuex'
import Constants from '@constants'

const { mapGetters: requestGetters, mapActions: requestActions } =
  createNamespacedHelpers(`status/${Constants.REQUEST}`)
const { mapGetters: problemGetters, mapActions: problemActions } =
  createNamespacedHelpers(`status/${Constants.PROBLEM}`)
const { mapGetters: changeGetters, mapActions: changeActions } =
  createNamespacedHelpers(`status/${Constants.CHANGE}`)
const { mapGetters: releaseGetters, mapActions: releaseActions } =
  createNamespacedHelpers(`status/${Constants.RELEASE}`)
const { mapGetters: taskGetters, mapActions: taskActions } =
  createNamespacedHelpers(`status/${Constants.TASK}`)
const { mapGetters: assetGetters, mapActions: assetActions } =
  createNamespacedHelpers(`status/${Constants.ASSET}`)
const { mapGetters: cmdbGetters, mapActions: cmdbActions } =
  createNamespacedHelpers(`status/${Constants.CMDB}`)
const { mapGetters: purchaseGetters, mapActions: purchaseActions } =
  createNamespacedHelpers(`status/${Constants.PURCHASE}`)
const { mapGetters: assetMovementGetters, mapActions: assetMovementActions } =
  createNamespacedHelpers(`status/${Constants.ASSET_MOVEMENT}`)

export const StatusComputed = {
  ...requestGetters({
    requestFindStatus: 'findStatus',
    requestStatus: 'status',
  }),
  ...problemGetters({
    problemFindStatus: 'findStatus',
    problemStatus: 'status',
  }),
  ...changeGetters({
    changeFindStatus: 'findStatus',
    changeFindStatusFromId: 'findStatusFromId',
    changeStatus: 'changeStatus',
  }),
  ...releaseGetters({
    releaseFindStatus: 'findStatus',
    releaseFindStatusFromId: 'findStatusFromId',
    releaseStatus: 'releaseStatus',
  }),
  ...taskGetters({
    taskFindStatus: 'findStatus',
    taskStatus: 'status',
  }),
  ...assetGetters({
    assetFindStatus: 'findStatus',
    assetStatus: 'status',
  }),
  ...cmdbGetters({
    cmdbFindStatus: 'findStatus',
    cmdbStatus: 'status',
  }),
  ...purchaseGetters({
    purchaseFindStatus: 'findStatus',
    purchaseFindStatusFromId: 'findStatusFromId',
    purchaseStatus: 'status',
  }),
  ...assetMovementGetters({
    asset_movementFindStatus: 'findStatus',
    asset_movementFindStatusFromId: 'findStatusFromId',
    asset_movementStatus: 'assetMovementStatus',
  }),
}

export const StatusActions = {
  ...requestActions({
    fetchRequestStatus: 'fetch',
  }),
  ...problemActions({
    fetchProblemStatus: 'fetch',
  }),
  ...changeActions({
    fetchChangeStatus: 'fetch',
  }),
  ...releaseActions({
    fetchReleaseStatus: 'fetch',
  }),
  ...taskActions({
    fetchTaskStatus: 'fetch',
  }),
  ...assetActions({
    fetchAssetStatus: 'fetch',
  }),
  ...cmdbActions({
    fetchCmdbStatus: 'fetch',
  }),
  ...purchaseActions({
    fetchPurchaseStatus: 'fetch',
  }),
  ...assetMovementActions({
    fetchAsset_movementStatus: 'fetch',
  }),
}
