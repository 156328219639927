import { fetchStatusesApi } from './api'
import GroupBy from 'lodash/groupBy'
import Constants from '@constants'
import { isLoggedIn, isPortalLogin } from '@utils/auth'
import { getRootPluaralTranslator } from '@utils/get-module-translator'

const orderedStages = [
  'submitted',
  'planning',
  'approval',
  'implementation',
  'review',
  'close',
]

const releaseOrderedStages = [
  'submitted',
  'planning',
  'approval',
  'build',
  'testing',
  'deployment',
  'review',
  'close',
]

const assetMovementOrderedStages = [
  'request_transfer',
  'transfer_approval',
  'initiate_transfer',
  'receive_transfer',
  'request_return',
  'return_approval',
  'initiate_return',
  'receive_return',
  'complete',
]

function getStatusModule(moduleName) {
  const vuexModule = {
    namespaced: true,
  }
  vuexModule.state = {
    status: [],
  }

  vuexModule.getters = {
    status(state) {
      const status = state.status
      return status.map((p) => ({
        color: p.color,
        key: p.id,
        text:
          isPortalLogin() && moduleName === Constants.REQUEST
            ? p.supportPortalStatusName
            : p.name,
        systemName: p.systemName,
        archived: p.archived,
        default: p.default,
        runSla: p.runSla,
        // stage: p.stage,
      }))
    },
    changeStatus(state) {
      const __tc = getRootPluaralTranslator()
      const status = state.status.map((p) => ({
        color: p.color,
        key: p.id,
        text: `${p.stage ? __tc(p.stage) : ''}: ${p.name}`,
        systemName: p.systemName,
        name: p.name,
        archived: p.archived,
        default: p.default,
        runSla: p.runSla,
        stage: p.stage,
      }))
      const groupedStatus = GroupBy(status, 'stage')
      let stageWiseStatus = []
      orderedStages.forEach((stage) => {
        if (groupedStatus[stage]) {
          const ss = groupedStatus[stage]
          stageWiseStatus = stageWiseStatus.concat(ss)
        }
      })
      return stageWiseStatus
    },
    releaseStatus(state) {
      const __tc = getRootPluaralTranslator()
      const status = state.status.map((p) => ({
        color: p.color,
        key: p.id,
        text: `${p.stage ? __tc(p.stage) : ''}: ${p.name}`,
        systemName: p.systemName,
        name: p.name,
        archived: p.archived,
        default: p.default,
        runSla: p.runSla,
        stage: p.stage,
      }))
      const groupedStatus = GroupBy(status, 'stage')
      let stageWiseStatus = []
      releaseOrderedStages.forEach((stage) => {
        if (groupedStatus[stage]) {
          const ss = groupedStatus[stage]
          stageWiseStatus = stageWiseStatus.concat(ss)
        }
      })
      return stageWiseStatus
    },
    assetMovementStatus(state) {
      const __tc = getRootPluaralTranslator()
      const status = state.status.map((p) => ({
        color: p.color,
        key: p.id,
        text: `${p.stage ? `${__tc(p.stage)}:` : ''} ${p.name}`,
        systemName: p.systemName,
        name: p.name,
        archived: p.archived,
        default: p.default,
        runSla: p.runSla,
        stage: p.stage,
      }))
      const groupedStatus = GroupBy(status, 'stage')
      let stageWiseStatus = status.filter((s) => !s.stage)
      assetMovementOrderedStages.forEach((stage) => {
        if (groupedStatus[stage]) {
          const ss = groupedStatus[stage]
          stageWiseStatus = stageWiseStatus.concat(ss)
        }
      })
      return stageWiseStatus
    },
    findStatus(state) {
      return (statusName, changeStage) => {
        const statuses = state.status
        return statuses
          ? statuses.find(
              ({ systemName, stage }) =>
                (systemName || '').toLowerCase() ===
                  (statusName || '').toLowerCase() &&
                (changeStage
                  ? (stage || '').toLowerCase() === changeStage.toLowerCase()
                  : true)
            )
          : undefined
      }
    },
    findStatusFromId(state) {
      return (id) => {
        const statuses = state.status
        return statuses ? statuses.find((s) => s.id === id) : undefined
      }
    },
  }

  vuexModule.mutations = {
    SET_STATUS(state, data) {
      state.status = data
    },
  }

  vuexModule.actions = {
    init({ dispatch }) {
      if (isLoggedIn()) {
        dispatch('fetch')
      }
    },

    fetch({ commit, rootGetters }, params) {
      const availableModules = rootGetters['license/availableModules']
      const assetChildModules =
        availableModules.indexOf(Constants.ASSET) >= 0
          ? [Constants.ASSET_MOVEMENT]
          : []
      if (
        [...availableModules, ...assetChildModules, Constants.TASK].indexOf(
          moduleName
        ) >= 0
      ) {
        return fetchStatusesApi(moduleName, true, params).then((data) =>
          commit('SET_STATUS', Object.freeze(data))
        )
      } else {
        Promise.resolve()
      }
    },

    /**
     * destroy all states when user is logged out
     */
    destroy({ commit }) {
      commit('SET_STATUS', [])
    },
  }
  return vuexModule
}

export const modules = {
  request: getStatusModule(Constants.REQUEST),
  problem: getStatusModule(Constants.PROBLEM),
  change: getStatusModule(Constants.CHANGE),
  release: getStatusModule(Constants.RELEASE),
  asset: getStatusModule(Constants.ASSET),
  cmdb: getStatusModule(Constants.CMDB),
  purchase: getStatusModule(Constants.PURCHASE),
  task: getStatusModule(Constants.TASK),
  asset_movement: getStatusModule(Constants.ASSET_MOVEMENT),
}
