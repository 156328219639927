import { createNamespacedHelpers } from 'vuex'

const { mapGetters: currencyGetters, mapActions: currencyActions } =
  createNamespacedHelpers('currency')

export const CurrencyComputed = {
  ...currencyGetters(['currencyOptions']),
}

export const CurrencyMethods = {
  ...currencyActions({
    fetchCurrency: 'fetch',
  }),
}
