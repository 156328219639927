import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('releaseType')

export const ReleaseTypeComputed = {
  ...mapGetters({
    releaseTypeOptions: 'releaseTypeOptions',
    isReleaseTypeLoaded: 'isReleaseTypeLoaded',
  }),
}

export const ReleaseTypeMethods = mapActions({
  fetchReleaseTypes: 'fetch',
})
