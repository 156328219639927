import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('riskType')

export const RiskTypeComputed = {
  ...mapGetters({
    riskTypeOptions: 'riskTypeOptions',
    isRiskTypeLoaded: 'isRiskTypeLoaded',
  }),
}

export const RiskTypeMethods = mapActions({
  fetchRiskTypes: 'fetch',
})
