import GroupBy from 'lodash/groupBy'
import api from '@api'

export const TransformKeyword = (keyword) => {
  return {
    keyword: keyword.keyword,
    name: keyword.displayName,
    group: keyword.groupName,
    customFieldType: keyword.fieldType,
  }
}

export function getKeywordsApi(
  moduleName,
  isResponsetemplate = false,
  additionalUrlParams = {}
) {
  return api
    .get(`${moduleName}/keywords`, {
      params: {
        isResponsetemplate,
        ...additionalUrlParams,
      },
    })
    .then((data) => GroupBy(data.keywords.map(TransformKeyword), 'group'))
}

export function getPrintTemplateKeywordsApi(
  moduleName,
  isResponsetemplate = false,
  additionalUrlParams = {}
) {
  return api.get(`${moduleName}/keywords`, {
    params: {
      isResponsetemplate,
      ...additionalUrlParams,
    },
  })
}

export function getServiceCatalogKeywordsApi(id, additionalParams = {}) {
  return api
    .get(`/service_catalog/${id}/keywords`, {
      params: {
        ...additionalParams,
      },
    })
    .then((data) => {
      const serviceCatalogKeywords = data.keywords.map((m) => ({
        groupName: m.groupName,
        displayName: m.displayName,
        customFieldType: m.fieldType,
        model: m.model,
        ...(m.fieldType &&
        ['Request Custom Fields', 'Service Catalog Custom Fields'].indexOf(
          m.groupName
        ) >= 0 &&
        additionalParams.definedPropertyType === 'print_template_type'
          ? {
              keyword: `{#custom.${m.model}.${m.displayName}#}`,
            }
          : { keyword: m.keyword }),
      }))
      return {
        keywords: GroupBy(
          serviceCatalogKeywords.map(TransformKeyword),
          'group'
        ),
      }
    })
}

export function getApiFieldKeywordsApi(
  moduleName = 'request',
  additionalUrlParams = {}
) {
  return api
    .get(`apifield/${moduleName}/keywords`, {
      params: {
        ...additionalUrlParams,
      },
    })
    .then((data) => {
      return {
        keywords: GroupBy(data.keywords.map(TransformKeyword), 'group'),
        options: data.supportedFields.map((k) => ({
          text: k.displayName,
          key: k.keyword,
        })),
      }
    })
}

export function getWebhookKeywordsApi(
  moduleName = 'request',
  additionalUrlParams = {}
) {
  return api
    .get(`webHook/${moduleName}/keywords`, {
      params: {
        ...additionalUrlParams,
      },
    })
    .then((data) => {
      return {
        keywords: GroupBy(data.keywords.map(TransformKeyword), 'group'),
        options: data.supportedFields.map((k) => ({
          text: k.displayName,
          key: k.keyword,
        })),
      }
    })
}
