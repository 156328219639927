import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('softwareType')

export const SoftwareTypeComputed = {
  ...mapGetters(['softwareTypeOptions']),
}

export const SoftwareTypeMethods = {
  ...mapActions({
    fetchsoftwareType: 'fetch',
  }),
}
