import { isLoggedIn } from '@utils/auth'
import { LANGUAGE_MAP } from '@utils/language-map'
import { getOrganizationApi, updateOrganizationApi } from './api'

export const state = {
  organization: {},
  organizationIsRtl: false,
}

export const getters = {
  organization(state) {
    return state.organization
  },
  defaultTimeZone(state) {
    return state.organization.timezone
  },
  multiTenantEnabled(state) {
    return state.organization.multiTenantEnabled
  },
  multiTenantEnvironment(state) {
    return state.organization.multiTenantEnvironment
  },
  organizationIsRtl(state) {
    if (state.organization) {
      const lang = LANGUAGE_MAP[state.organization.defaultLanguage]
      return ['ar'].indexOf(lang) >= 0
    }
    return false
  },
  enabledCurrency(state) {
    return state.organization.currencyIds
  },
}

export const mutations = {
  SET_ORGANIZATION(state, payload) {
    state.organization = payload
  },
}

export const actions = {
  init({ dispatch }) {
    if (isLoggedIn()) {
      dispatch('fetch')
    }
  },

  fetch({ commit }, params) {
    getOrganizationApi(params).then((data) => {
      commit('SET_ORGANIZATION', data)
      return data
    })
  },

  updateOrganization({ commit }, payload) {
    return updateOrganizationApi(payload).then((data) => {
      commit('SET_ORGANIZATION', data)
    })
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    // commit('SET_ORGANIZATION', {})
  },
}
