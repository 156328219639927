import { createNamespacedHelpers } from 'vuex'

const {
  mapState,
  mapGetters: priorityGetters,
  mapActions: priorityActions,
} = createNamespacedHelpers('priority')

export const PriorityComputed = {
  ...mapState({
    priorities: (state) => state.priorities,
  }),
  ...priorityGetters(['priorityOptions']),
}

export const PriorityActions = {
  ...priorityActions({
    fetchPriority: 'fetch',
  }),
}
