import api from '@api'
import SortBy from 'lodash/sortBy'
import { transformLocationRecursive, transformLocation } from '@data/location'
import { isLoggedIn } from '@utils/auth'
import {
  buildFlatQualificationStructure,
  buildNameFilterQuery,
} from '@data/qualification'

export function getLocationsApi(archived = false, params = {}) {
  return api
    .get(`${!isLoggedIn() ? '/public' : ''}/location`, {
      params: {
        ...(archived ? { archived: true } : {}),
        ...params,
      },
      notify: false,
    })
    .then((data) => {
      return transformLocationRecursive(SortBy(data, (d) => d.node.objOrder))
    })
}

export function searchLocationsApi(
  { name, selectedName, isPublic, quals },
  limit,
  offset,
  params = {}
) {
  return api
    .post(
      `${isPublic ? '/public' : ''}/location/search/byqual`,
      {
        ...(name || selectedName || quals
          ? {
              qualDetails: buildFlatQualificationStructure(
                [
                  name && buildNameFilterQuery(name),
                  selectedName && buildNameFilterQuery(selectedName),
                  ...(quals || []),
                ].filter(Boolean)
              ),
            }
          : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: SortBy(data.objectList || [], 'objOrder').map(transformLocation),
        total: data.totalCount,
      }
    })
}
