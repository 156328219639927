import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('task')

export const TaskComputed = {
  ...mapGetters(['taskTypeOptions', 'defaultTaskType']),
}

export const TaskMethods = {
  ...mapActions({
    fetchTaskType: 'fetch',
  }),
}
