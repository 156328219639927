import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('impact')

export const ImpactComputed = {
  ...mapGetters(['impactOptions']),
}

export const ImpactMethods = {
  ...mapActions({
    fetchImpact: 'fetch',
  }),
}
