import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('reasonType')

export const ReasonTypeComputed = {
  ...mapGetters({
    reasonTypeOptions: 'reasonTypeOptions',
    isReasonTypeLoaded: 'isReasonTypeLoaded',
  }),
}

export const ReasonTypeMethods = mapActions({
  fetchReasonTypes: 'fetch',
})
