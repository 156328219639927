import { getProjectRiskTypeApi } from './api'

export const state = {
  projectRiskTypes: [],
  isProjectRiskTypeLoaded: false,
}

export const getters = {
  projectRiskTypeOptions(state) {
    return state.projectRiskTypes
  },
  isProjectRiskTypeLoaded(state) {
    return state.isProjectRiskTypeLoaded
  },
}

export const mutations = {
  SET_PROJECT_RISK_TYPE(state, payload) {
    state.projectRiskTypes = payload
    state.isProjectRiskTypeLoaded = true
  },
  SET_LOADING(state, payload) {
    state.isProjectRiskTypeLoaded = payload
  },
}

export const actions = {
  fetch({ commit }) {
    getProjectRiskTypeApi(true).then((data) =>
      commit('SET_PROJECT_RISK_TYPE', Object.freeze(data))
    )
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_PROJECT_RISK_TYPE', [])
    commit('SET_LOADING', false)
  },
}
