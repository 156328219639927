import api from '@api'
import SortBy from 'lodash/sortBy'
import {
  transformDepartment,
  transformDepartmentRecursive,
} from '@data/department'
import { isLoggedIn } from '@utils/auth'
import {
  buildFlatQualificationStructure,
  buildNameFilterQuery,
} from '@/src/data/qualification'

export function fetchDepartmentsApi(archived = false, params = {}) {
  return api
    .get(`${!isLoggedIn() ? '/public' : ''}/department`, {
      params: {
        ...(archived ? { archived: true } : {}),
        ...params,
      },
      notify: false,
    })
    .then((data) => {
      return transformDepartmentRecursive(SortBy(data, (d) => d.node.objOrder))
    })
}

export function getDepartmentsApi(
  { name, selectedName, isPublic, quals },
  limit,
  offset,
  params = {}
) {
  return api
    .post(
      `${isPublic ? '/public' : ''}/department/search/byqual`,
      {
        ...(name || selectedName || quals
          ? {
              qualDetails: buildFlatQualificationStructure(
                [
                  name && buildNameFilterQuery(name),
                  selectedName && buildNameFilterQuery(selectedName),
                  ...(quals || []),
                ].filter(Boolean)
              ),
            }
          : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: SortBy(data.objectList || [], 'objOrder').map(
          transformDepartment
        ),
        total: data.totalCount,
      }
    })
}

// export function getDepartmentConfigApi(moduleName) {
//   return api
//     .get(`/public/${moduleName}/hierarchyManagement`, {
//       loader: false,
//       notify: false,
//     })
//     .then(transformDepartmentHierarchyConfig)
// }
