import api from '@api'
import { transformUserForVuex } from '@data/user'
import { isLoggedIn } from '@utils/auth'

export function fetchTechniciansApi(params = {}) {
  return api
    .get(`${!isLoggedIn() ? '/public' : ''}/technician/list`, {
      params: {
        ...params,
      },
      loader: false,
      notify: false,
    })
    .then(({ objectList, totalCount }) => {
      const users = (objectList || []).map(transformUserForVuex)
      return { users, total: totalCount }
    })
}

export function getTechniciansApi(offset, size, params = {}) {
  return api
    .post(
      `${!isLoggedIn() ? '/public' : ''}/technician/search/byqual`,
      {},
      {
        params: {
          offset: offset || 0,
          size: size || 1000,
          ...params,
        },
        loader: false,
        notify: false,
      }
    )
    .then(({ objectList, totalCount }) => {
      const users = (objectList || []).map(transformUserForVuex)
      return { users, total: totalCount }
    })
}
