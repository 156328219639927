import api from '@api'

export function getReleaseReasonTypeApi(archived = false) {
  return api
    .post(
      `/release/reasontype/search/byqual`,
      {},
      {
        params: {
          ...(archived ? { archived: true } : {}),
          offset: 0,
          size: 1000,
        },
        notify: false,
      }
    )
    .then((data) => {
      return (data.objectList || []).map((r) => ({
        key: r.id,
        id: r.id,
        value: r.id,
        text: r.name,
        label: r.name,
        archived: r.removed,
        color: r.colourCode,
      }))
    })
}
