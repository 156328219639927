import { getChangeTypeApi } from './api'

export const state = {
  changeTypes: [],
  isChangeTypeLoaded: false,
}

export const getters = {
  changeTypeOptions(state) {
    return state.changeTypes
  },
  changeTypeColorsMap(state) {
    const type = {}
    state.changeTypes.forEach((t) => {
      type[t.id] = t.color
    })
    return type
  },
  isChangeTypeLoaded(state) {
    return state.isChangeTypeLoaded
  },
}

export const mutations = {
  SET_CHANGE_TYPE(state, payload) {
    state.changeTypes = payload
    state.isChangeTypeLoaded = true
  },
  SET_LOADING(state, payload) {
    state.isChangeTypeLoaded = payload
  },
}

export const actions = {
  init({ dispatch }) {},

  fetch({ commit }) {
    getChangeTypeApi(true).then((data) =>
      commit('SET_CHANGE_TYPE', Object.freeze(data))
    )
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_CHANGE_TYPE', [])
    commit('SET_LOADING', false)
  },
}
