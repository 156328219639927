import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters: technicianGetters,
  mapActions: technicianActions,
} = createNamespacedHelpers('userGroup/technician')
const {
  mapGetters: requesterGetters,
  mapActions: requesterActions,
} = createNamespacedHelpers('userGroup/requester')

export const UserGroupsComputed = {
  ...technicianGetters({
    technicianGroupsOptions: 'groupsAsOptions',
    technicianGetGroupById: 'getGroupById',
  }),
  ...requesterGetters({
    requesterGroupsOptions: 'groupsAsOptions',
    requesterGetGroupById: 'getGroupById',
  }),
}

export const UserGroupsMethods = {
  ...technicianActions({ technicianGroups: 'fetch' }),
  ...requesterActions({ requesterGroups: 'fetch' }),
}
