import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('projectRiskType')

export const ProjectRiskTypeComputed = {
  ...mapGetters({
    projectRiskTypeOptions: 'projectRiskTypeOptions',
    isProjectRiskTypeLoaded: 'isProjectRiskTypeLoaded',
  }),
}

export const ProjectRiskTypeMethods = mapActions({
  fetchProjectRiskTypes: 'fetch',
})
