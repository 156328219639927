import { getGuidedTourApi, updateGuidedTourApi } from './api'
export const state = {
  guidedTour: getSavedState('guidedTour'),
}

export const getters = {
  guidedTour(state) {
    return state.guidedTour
  },
  setupCompleted(state) {
    return state.guidedTour.setupCompleted
  },
  setupCompletedGuides(state) {
    return state.guidedTour.setupCompletedGuides
  },
  setupCompletedSections(state) {
    return state.guidedTour.setupCompletedSections
  },
  setupCompletedSteps(state) {
    return state.guidedTour.setupCompletedSteps
  },
}

export const mutations = {
  SET_GUIDED_TOUR(state, payload) {
    state.guidedTour = payload
    saveState('guidedTour', payload)
  },
}

export const actions = {
  init({ dispatch }) {
    dispatch('fetch')
  },

  fetch({ commit }, params) {
    return getGuidedTourApi(params).then((data) => {
      commit('SET_GUIDED_TOUR', data)
    })
  },

  updateGuidedTour({ commit, dispatch }, payload) {
    return updateGuidedTourApi(payload).then((data) => {
      commit('SET_GUIDED_TOUR', data)
      dispatch('fetch')
    })
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_GUIDED_TOUR', {})
  },
}

export function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}
