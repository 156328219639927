import api from '@api'
import { getRootTranslator } from '@utils/get-module-translator'
import { isLoggedIn } from '@utils/auth'
import Moment from 'moment'
const __rootT = getRootTranslator()
const transformOrganization = (organization) => {
  return {
    id: organization.id,
    name: organization.name,
    currencyIds: organization.currencyIds,
    timezone: organization.timezone,
    email: organization.email,
    contactNumber: organization.contactNumber,
    agentActivationCode: organization.agentActivationCode,
    defaultLanguage: organization.defaultLanguage,
    mobileAgentActivationCode: organization.mobileAgentActivationCode,
    multiTenantEnabled: organization.multiTenantEnabled,
    multiTenantEnvironment: organization.multiTenantEnvironment,
    ...(organization.dateTimeFormat
      ? {
          dateTimeFormat: Moment().toMomentFormatString(
            organization.dateTimeFormat
          ),
        }
      : {}),
    ...(organization.dateFormat
      ? {
          dateFormat: Moment().toMomentFormatString(organization.dateFormat),
        }
      : {}),
    ...(organization.timeFormat
      ? {
          timeFormat: Moment().toMomentFormatString(organization.timeFormat),
        }
      : {}),
  }
}

const transformOrganizationForServer = (organization) => {
  return {
    id: organization.id,
    name: organization.name,
    currencyIds: organization.currencyIds,
    timezone: organization.timezone,
    email: organization.email,
    contactNumber: organization.contactNumber,
    agentActivationCode: organization.agentActivationCode,
    defaultLanguage: organization.defaultLanguage,
    mobileAgentActivationCode: organization.mobileAgentActivationCode,
    multiTenantEnvironment: organization.multiTenantEnvironment,
    ...(organization.dateTimeFormat
      ? {
          dateTimeFormat: Moment().toJDFString(organization.dateTimeFormat),
        }
      : {}),
    ...(organization.dateFormat
      ? {
          dateFormat: Moment().toJDFString(organization.dateFormat),
        }
      : {}),
    ...(organization.timeFormat
      ? {
          timeFormat: Moment().toJDFString(organization.timeFormat),
        }
      : {}),
  }
}

export function getOrganizationApi(params) {
  return api
    .get(`${isLoggedIn() ? '/' : '/public/'}organization`, { params })
    .then(transformOrganization)
}

export function updateOrganizationApi(data) {
  return api
    .patch('/organization', transformOrganizationForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootT('account'),
      }),
    })
    .then(transformOrganization)
}

export function getReleaseVersionApi() {
  return api.get('/myuser/version').then((data) => {
    let obj = {}
    data.map((d) => {
      obj[d.key] = d.value
    })
    return obj
  })
}
