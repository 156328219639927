import FindIndex from 'lodash/findIndex'
import Constants from '@constants'
import { isLoggedIn } from '@utils/auth'
import {
  getSavedSearchApi,
  saveSearchApi,
  updateSearchApi,
  removeSearchApi,
} from './api'

function getPageModule(moduleName, configs = {}) {
  const vuexModule = {
    namespaced: true,
  }
  vuexModule.state = {
    loading: true,
    searches: [],
    selectedSearch: {},
    searchCriterias: [],
    searchDirty: false,
    currentListIds: [],
    pageData: {},
  }

  vuexModule.getters = {
    loading(state) {
      return state.loading
    },
    searches(state) {
      return state.searches
    },
    selected(state) {
      return state.selectedSearch
    },
    searchCriterias(state) {
      return state.searchCriterias
    },
    dirty(state) {
      return state.searchDirty
    },
    pageData(state) {
      return state.pageData
    },
    getNextItemId(state) {
      return (itemId) => {
        const currentIndex = state.currentListIds
          .map(String)
          .indexOf(String(itemId))
        if (currentIndex < 0) {
          return Promise.resolve()
        }
        if (state.currentListIds[currentIndex + 1]) {
          return Promise.resolve(state.currentListIds[currentIndex + 1])
        }
        // @TODO if its last item and has next page then fire api call here
        return Promise.resolve()
      }
    },
    getPreviousItemId(state) {
      return (itemId) => {
        const currentIndex = state.currentListIds
          .map(String)
          .indexOf(String(itemId))
        if (currentIndex < 0) {
          return Promise.resolve()
        }
        if (state.currentListIds[currentIndex - 1]) {
          return Promise.resolve(state.currentListIds[currentIndex - 1])
        }
        // @TODO if its first item and has previous page then fire api call here
        return Promise.resolve()
      }
    },
  }

  vuexModule.mutations = {
    SET_SEARCHES(state, data) {
      if (data.length === 0) {
        state.loading = true
      } else {
        state.loading = false
      }
      state.searches = data.searches
      state.selectedSearch = data.selectedSearch || {}
      state.searchCriterias = state.selectedSearch.qualifications || []
    },
    SET_SELECTED_SEARCH(state, search) {
      state.selectedSearch = search
      state.searchCriterias = (state.selectedSearch || {}).qualifications || []
      state.searchDirty = false
    },
    ADD_SEARCH(state, search) {
      state.searches = [...state.searches, search]
      state.selectedSearch = search
      state.searchCriterias = state.selectedSearch.qualifications || []
      state.searchDirty = false
    },
    UPDATE_SEARCH(state, search) {
      const index = FindIndex(state.searches, { id: search.id })
      if (index !== -1) {
        state.searches = [
          ...state.searches.slice(0, index),
          search,
          ...state.searches.slice(index + 1),
        ]
        if (state.selectedSearch && state.selectedSearch.id === search.id) {
          state.selectedSearch = search
          state.searchCriterias = state.selectedSearch.qualifications || []
        }
        state.searchDirty = false
      }
    },
    REMOVE_SEARCH(state, payload) {
      state.searches = state.searches.filter(({ id }) => id !== payload.id)
      if (state.selectedSearch && state.selectedSearch.id === payload.id) {
        state.selectedSearch = payload.selectedSearch || state.searches[0]
        state.searchCriterias = state.selectedSearch.qualifications || []
      }
      state.searchDirty = false
    },
    UPDATE_SEARCH_CRITERIAS(state, payload) {
      state.searchCriterias = payload
      state.searchDirty = true
    },
    SET_LIST_ID(state, payload) {
      state.currentListIds = payload
    },
    SET_DIRTY(state, type) {
      state.searchDirty = type
    },
    SET_PAGE_DATA(state, pageData) {
      state.pageData = pageData
    },
  }
  vuexModule.actions = {
    init({ dispatch }) {
      if (isLoggedIn() && configs.autoFetch !== false) {
        dispatch('fetch')
      }
    },

    fetch({ commit, rootGetters }, params) {
      // check module license
      const availableModules = rootGetters['license/availableModules']
      // check module permission
      const myAllowedModules = rootGetters['preference/myAllowedModules']
      if (availableModules.length === 0) {
        // if no available module is found then try to refresh page to start fresh.
        window.location.reload()
      }
      const assetChildModules =
        availableModules.indexOf(Constants.ASSET) >= 0
          ? [
              Constants.ASSET_HARDWARE,
              Constants.ASSET_SOFTWARE,
              Constants.ASSET_NON_IT,
              Constants.ASSET_CONSUMABLE,
              Constants.ASSET_MOVEMENT,
            ]
          : []
      const patchChildModules =
        availableModules.indexOf(Constants.PATCH) >= 0
          ? [Constants.PATCH_COMPUTERS, Constants.PATCH_REMOTE_DEPLOYMENT]
          : []
      const deploymentChildModules =
        availableModules.indexOf(Constants.DEPLOYMENT) >= 0
          ? [
              Constants.PACKAGES_COMPUTERS,
              Constants.PACKAGES_REMOTE_DEPLOYMENT,
              Constants.REGISTRY_REMOTE_DEPLOYMENT,
            ]
          : []
      const checkUserSurveyModulePermission =
        myAllowedModules.indexOf('admin.user_survey_management') >= 0
          ? [Constants.USER_SURVEY]
          : []
      if (
        [
          ...availableModules,
          ...assetChildModules,
          ...patchChildModules,
          ...deploymentChildModules,
          ...checkUserSurveyModulePermission,
          Constants.TASK,
          Constants.USER,
        ].indexOf(moduleName) === -1
      ) {
        return Promise.resolve()
      }
      const defaultSearchId =
        rootGetters['preference/defaultSearchId'](moduleName)
      return getSavedSearchApi(moduleName, undefined, undefined, params).then(
        (data) => {
          const searchItem = data.find(({ id }) => id === defaultSearchId)
          commit('SET_SEARCHES', {
            searches: data.map(Object.freeze),
            selectedSearch: Object.freeze(searchItem || data[0]),
          })
        }
      )
    },

    selectSearch({ commit }, payload) {
      commit('SET_SELECTED_SEARCH', Object.freeze(payload))
    },

    addSearch({ commit }, payload) {
      return saveSearchApi(payload.moduleName, payload.search).then((data) => {
        commit('ADD_SEARCH', Object.freeze(data))
      })
    },

    setListIds({ commit }, payload) {
      commit('SET_LIST_ID', payload)
    },

    setPageData({ commit }, payload) {
      commit('SET_PAGE_DATA', payload)
    },

    setDirty({ commit }, payload) {
      commit('SET_DIRTY', payload)
    },

    updateSearch({ commit }, payload) {
      return updateSearchApi(payload.moduleName, payload.search).then(
        (data) => {
          commit('UPDATE_SEARCH', Object.freeze(data))
        }
      )
    },

    removeSearch({ commit, state, rootGetters }, payload) {
      return removeSearchApi(moduleName, payload).then(() => {
        const defaultSearchId =
          rootGetters['preference/defaultSearchId'](moduleName)
        const foundItem = state.searches.find(
          ({ id }) => id === defaultSearchId
        )
        commit('REMOVE_SEARCH', {
          id: payload,
          selectedSearch: foundItem,
        })
      })
    },

    updateSearchCriterias({ commit }, payload) {
      commit('UPDATE_SEARCH_CRITERIAS', payload)
    },

    reset({ commit, state, rootGetters }) {
      if (configs.autoFetch !== false) {
        commit('SET_LIST_ID', [])
        const defaultSearchId =
          rootGetters['preference/defaultSearchId'](moduleName)
        const foundItem = state.searches.find(
          ({ id }) => id === defaultSearchId
        )
        commit('SET_SELECTED_SEARCH', foundItem || state.searches[0])
      }
      commit('SET_PAGE_DATA', {})
      if (configs.autoFetch === false) {
        commit('UPDATE_SEARCH_CRITERIAS', [])
        commit('SET_DIRTY', false)
      }
    },

    /**
     * destroy all states when user is logged out
     */
    destroy({ commit }) {
      commit('SET_SEARCHES', {
        searches: [],
        selectedSearch: {},
      })
      commit('SET_SELECTED_SEARCH', {})
      commit('SET_PAGE_DATA', {})
      if (configs.autoFetch === false) {
        commit('UPDATE_SEARCH_CRITERIAS', [])
        commit('SET_DIRTY', false)
      }
    },
  }
  return vuexModule
}

export const modules = {
  request: getPageModule(Constants.REQUEST),
  problem: getPageModule(Constants.PROBLEM),
  change: getPageModule(Constants.CHANGE),
  release: getPageModule(Constants.RELEASE),
  task: getPageModule(Constants.TASK),
  user: getPageModule(Constants.USER),
  knowledge: getPageModule(Constants.KNOWLEDGE, { autoFetch: false }),
  dashboard: getPageModule('dashboard', { autoFetch: false }),
  asset_hardware: getPageModule(Constants.ASSET_HARDWARE),
  asset_software: getPageModule(Constants.ASSET_SOFTWARE),
  asset_non_it: getPageModule(Constants.ASSET_NON_IT),
  asset_consumable: getPageModule(Constants.ASSET_CONSUMABLE),
  asset_movement: getPageModule(Constants.ASSET_MOVEMENT),
  contract: getPageModule(Constants.CONTRACT),
  purchase: getPageModule(Constants.PURCHASE),
  user_survey: getPageModule(Constants.USER_SURVEY),
  project: getPageModule(Constants.PROJECT),
  patch: getPageModule(Constants.PATCH),
  // agent: getPageModule(Constants.AGENT),
  patch_computers: getPageModule(Constants.PATCH_COMPUTERS),
  packages_computers: getPageModule(Constants.PACKAGES_COMPUTERS),
  patch_remote_deployment: getPageModule(Constants.PATCH_REMOTE_DEPLOYMENT),
  packages_remote_deployment: getPageModule(
    Constants.PACKAGES_REMOTE_DEPLOYMENT
  ),
  registry_remote_deployment: getPageModule(
    Constants.REGISTRY_REMOTE_DEPLOYMENT
  ),
  cmdb: getPageModule(Constants.CMDB),
}
