import api from '@api'
import SortBy from 'lodash/sortBy'
import { transformSoftwareType } from '@data/software-type'

export function fetchSoftwareTypeApi(params = {}) {
  return api
    .post(
      '/asset/softwaretype/search/byqual',
      {},
      {
        params: {
          ...params,
          size: 1000,
          offset: 0,
        },
        notify: false,
      }
    )
    .then((data) =>
      SortBy(data.objectList || [], 'objOrder').map(transformSoftwareType)
    )
}
