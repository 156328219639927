import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('contractType')

export const ContractTypeComputed = {
  ...mapGetters({
    contractTypeOptions: 'contractTypeOptions',
    isContractTypesLoaded: 'isContractTypesLoaded',
  }),
}

export const ContractTypeMethods = mapActions({
  fetchContractTypes: 'fetch',
})
