import api from '@api'
import SortBy from 'lodash/sortBy'
import Constants from '@constants'
import { transformStatus } from '@data/status'
import { isLoggedIn } from '@utils/auth'

export function fetchStatusesApi(moduleName, archived = false, params = {}) {
  return api
    .get(`${!isLoggedIn() ? '/public' : ''}/${moduleName}/status/list`, {
      params: {
        ...params,
        ...(archived ? { archived: true } : {}),
      },
      loader: false,
      notify: false,
    })
    .then((data) => {
      const status = SortBy(data.objectList, 'objOrder')
      if (moduleName === Constants.TASK) {
        return status.map(transformStatus).map((s) => ({
          ...s,
          // @TODO remove this direct status name
          archived: s.archived ? s.archived : s.name === 'Not Started',
        }))
      }
      return status.map(transformStatus)
    })
}

export function getStatusesApi(moduleName, archived = false, params = {}) {
  return api
    .post(
      `${!isLoggedIn() ? '/public' : ''}/${moduleName}/status/search/byqual`,
      {},
      {
        params: {
          ...params,
          ...(archived ? { archived: true } : {}),
        },
        loader: false,
        notify: false,
      }
    )
    .then((data) => {
      const status = SortBy(data.objectList, 'objOrder')
      if (moduleName === Constants.TASK) {
        return status.map(transformStatus).map((s) => ({
          ...s,
          // @TODO remove this direct status name
          archived: s.archived ? s.archived : s.name === 'Not Started',
        }))
      }
      return status.map(transformStatus)
    })
}
