import { getReleaseRiskTypeApi } from './api'

export const state = {
  releaseReleaseRiskTypes: [],
  isReleaseRiskTypeLoaded: false,
}

export const getters = {
  releaseReleaseRiskTypeOptions(state) {
    return state.releaseReleaseRiskTypes
  },
  isReleaseRiskTypeLoaded(state) {
    return state.isReleaseRiskTypeLoaded
  },
}

export const mutations = {
  SET_RELEASE_RISK_TYPE(state, payload) {
    state.releaseReleaseRiskTypes = payload
    state.isReleaseRiskTypeLoaded = true
  },
  SET_LOADING(state, payload) {
    state.isReleaseRiskTypeLoaded = payload
  },
}

export const actions = {
  fetch({ commit }) {
    getReleaseRiskTypeApi(true).then((data) =>
      commit('SET_RELEASE_RISK_TYPE', Object.freeze(data))
    )
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_RELEASE_RISK_TYPE', [])
    commit('SET_LOADING', false)
  },
}
