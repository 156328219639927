import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('license')

export const LicenseComputed = {
  ...mapGetters({
    license: 'license',
    isTrial: 'isTrial',
    trialExpiresAt: 'trialExpiresAt',
    isLicenseExpiring: 'isLicenseExpiring',
    isTrialExpired: 'isTrialExpired',
    isLicenseExpired: 'isLicenseExpired',
    isTenantSuspended: 'isSuspended',
    availableModulesInLicense: 'availableModules',
    mspEnabled: 'mspEnabled',
    mspPortal: 'mspPortal',
    isSaasApplication: 'isSaasApplication',
    aiEnabled: 'aiEnabled',
  }),
}

export const LicenseMethods = mapActions({
  fetchLicense: 'fetch',
  resetLicense: 'updateLicense',
})
