import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters: assetTypeGetters,
  mapActions: assetTypeActions,
} = createNamespacedHelpers('assetType')

export const AssetTypeComputed = {
  ...assetTypeGetters({
    assetTypes: 'assetTypes',
  }),
}

export const AssetTypeMethods = {
  ...assetTypeActions({
    fetchAssetType: 'fetch',
  }),
}
