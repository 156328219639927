import { isLoggedIn } from '@utils/auth'
import { fetchImpactsApi } from './api'
export const state = {
  impacts: [],
}

export const getters = {
  impacts(state) {
    return state.impacts
  },
  impactOptions(state) {
    const impacts = state.impacts
    return impacts.map((p) => ({
      default: p.default,
      color: p.color,
      key: p.id,
      text: p.name,
    }))
  },
}

export const mutations = {
  SET_IMPACTS(state, data) {
    state.impacts = data
  },
}

export const actions = {
  init({ dispatch }) {
    if (isLoggedIn()) {
      dispatch('fetch')
    }
  },

  fetch({ commit }, params) {
    return fetchImpactsApi(params).then((data) => {
      commit('SET_IMPACTS', data)
      return data
    })
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_IMPACTS', [])
  },
}
