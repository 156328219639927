import { fetchCurrencyApi } from './api'
import { isLoggedIn } from '@utils/auth'

export const state = {
  currencyIds: [],
}

export const mutations = {
  SET_CURRENCY(state, newValue) {
    state.currencyIds = newValue
  },
}

export const getters = {
  currencyIds(state) {
    return state.currencyIds
  },
  currencyOptions(state) {
    return state.currencyIds
  },
}

export const actions = {
  init({ dispatch }) {
    if (isLoggedIn()) {
      dispatch('fetch')
    }
  },

  fetch({ commit }) {
    return fetchCurrencyApi().then((data) => {
      commit('SET_CURRENCY', data)
    })
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_CURRENCY', [])
  },
}
