import { getReleaseReasonTypeApi } from './api'

export const state = {
  releaseReasonTypes: [],
  isReleaseReasonTypeLoaded: false,
}

export const getters = {
  releaseReasonTypeOptions(state) {
    return state.releaseReasonTypes
  },
  isReleaseReasonTypeLoaded(state) {
    return state.isReleaseReasonTypeLoaded
  },
}

export const mutations = {
  SET_REASON_TYPE(state, payload) {
    state.releaseReasonTypes = payload
    state.isReleaseReasonTypeLoaded = true
  },
  SET_LOADING(state, payload) {
    state.isReleaseReasonTypeLoaded = payload
  },
}

export const actions = {
  fetch({ commit }) {
    getReleaseReasonTypeApi(true).then((data) =>
      commit('SET_REASON_TYPE', Object.freeze(data))
    )
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_REASON_TYPE', [])
    commit('SET_LOADING', false)
  },
}
