import api from '@api'
import {
  transformGuidedTour,
  transformGuidedTourForServer,
} from '@data/guided-tour.js'

export function getGuidedTourApi() {
  return api.get('/tourGuide').then((data) => transformGuidedTour(data))
}

export function updateGuidedTourApi(data) {
  return api
    .patch('/tourGuide', transformGuidedTourForServer(data))
    .then(transformGuidedTour)
}
