import { getBusinessServiceApi } from './api'

export const state = {
  services: [],
  isServicesLoaded: false,
}

export const getters = {
  businessServiceOptions(state) {
    return state.services
  },
  isBusinessServiceLoaded(state) {
    return state.isServicesLoaded
  },
}

export const mutations = {
  SET_BUSINESS_SERVICE(state, payload) {
    state.services = payload
    state.isServicesLoaded = true
  },
  SET_LOADING(state, payload) {
    state.isServicesLoaded = payload
  },
}

export const actions = {
  init({ dispatch }) {},

  fetch({ commit }) {
    getBusinessServiceApi(true).then((data) =>
      commit('SET_BUSINESS_SERVICE', Object.freeze(data))
    )
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_BUSINESS_SERVICE', [])
    commit('SET_LOADING', false)
  },
}
