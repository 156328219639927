import Constants from '@constants'
import { getFieldMappingApi } from './api'
import { isLoggedIn } from '@utils/auth'

function getFieldMappingModule(moduleName) {
  const vuexModule = {
    namespaced: true,
  }
  vuexModule.state = {
    fieldMapping: [],
  }

  vuexModule.getters = {
    fieldMapping(state) {
      return state.fieldMapping
    },
  }

  vuexModule.mutations = {
    SET_MAPPING(state, data) {
      state.fieldMapping = data
    },
  }

  vuexModule.actions = {
    init({ dispatch }) {
      if (isLoggedIn()) {
        dispatch('fetch')
      }
    },

    fetch({ commit, rootGetters }, params) {
      const availableModules = rootGetters['license/availableModules']
      if (availableModules.indexOf(moduleName) >= 0) {
        getFieldMappingApi(moduleName).then((data) => {
          commit('SET_MAPPING', Object.freeze(data))
        })
      }
    },

    /**
     * destroy all states when user is logged out
     */
    destroy({ commit }) {
      commit('SET_MAPPING', [])
    },
  }
  return vuexModule
}

export const modules = {
  request: getFieldMappingModule(Constants.REQUEST),
}
