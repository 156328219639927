import { isLoggedIn } from '@utils/auth'
import { getTimezoneApi } from './api'
export const state = {
  options: [],
}

export const getters = {
  options(state) {
    return state.options
  },
}

export const mutations = {
  SET_OPTIONS(state, payload) {
    state.options = payload
  },
}

export const actions = {
  init({ dispatch }) {
    if (isLoggedIn()) {
      dispatch('fetch')
    }
  },

  fetch({ commit }) {
    return getTimezoneApi().then((data) => {
      commit('SET_OPTIONS', Object.freeze(data))
      return data
    })
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_OPTIONS', [])
  },
}
