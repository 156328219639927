import api from '@api'

export function getTimezoneApi() {
  return api.get('/public/supported/timezones').then((data) => {
    const options = []
    Object.keys(data).forEach((k) => {
      options.push({ text: data[k], key: k })
    })
    return options
  })
}
