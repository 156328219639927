import { getProductTypeApi } from './api'

export const state = {
  productType: [],
  isTypesLoaded: false,
}

export const getters = {
  productTypeOptions(state) {
    return state.productType
  },
  isProductTypeLoaded(state) {
    return state.isTypesLoaded
  },
}

export const mutations = {
  SET_PRODUCT_TYPE(state, payload) {
    state.productType = payload
    state.isTypesLoaded = true
  },
  SET_LOADING(state, payload) {
    state.isTypesLoaded = payload
  },
}

export const actions = {
  init({ dispatch }) {},

  fetch({ commit }) {
    getProductTypeApi(true).then((data) =>
      commit('SET_PRODUCT_TYPE', Object.freeze(data))
    )
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_PRODUCT_TYPE', [])
    commit('SET_LOADING', false)
  },
}
