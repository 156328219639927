import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('timezone')

export const TimezoneComputed = {
  ...mapGetters({
    timezoneOptions: 'options',
  }),
}

export const TimezoneMethods = mapActions({
  fetchTimezone: 'fetch',
})
