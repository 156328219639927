import api from '@api'

export function getReleaseTypeApi(archived = false) {
  return api
    .post(
      `/release/releasetype/search/byqual`,
      {},
      {
        params: {
          ...(archived ? { archived: true } : {}),
          offset: 0,
          size: 1000,
        },
        notify: false,
      }
    )
    .then((data) => {
      return (data.objectList || []).map((c) => ({
        key: c.id,
        id: c.id,
        value: c.id,
        text: c.name,
        label: c.name,
        archived: c.removed,
        color: c.colourCode,
      }))
    })
}
