import api from '@api'
import Constants from '@constants'
import { transformSearch, transformSearchForServer } from '@data/search'
import { getRootTranslator } from '@utils/get-module-translator'
const __t = getRootTranslator()

export const transformModuleName = (moduleName) => {
  if (
    [Constants.PATCH_COMPUTERS, Constants.PACKAGES_COMPUTERS].indexOf(
      moduleName
    ) >= 0
  ) {
    return Constants.AGENT
  }
  if (
    [
      Constants.PATCH_REMOTE_DEPLOYMENT,
      Constants.PACKAGES_REMOTE_DEPLOYMENT,
    ].indexOf(moduleName) >= 0
  ) {
    return Constants.REMOTE_DEPLOYMENT
  }
  return moduleName
}

export function saveSearchApi(moduleName, search) {
  return api
    .post(
      `${moduleName}/qual`,
      transformSearchForServer(transformModuleName(moduleName), search),
      {
        message: __t('saved_successfully', { resource: __t('search') }),
      }
    )
    .then((data) => transformSearch(transformModuleName(moduleName), data))
}

export function updateSearchApi(moduleName, search) {
  return api
    .patch(
      `${moduleName}/qual/${search.id}`,
      transformSearchForServer(transformModuleName(moduleName), search),
      {
        message: __t('updated_successfully', { resource: __t('search') }),
      }
    )
    .then((data) => transformSearch(transformModuleName(moduleName), data))
}

export function getSavedSearchApi(moduleName, limit, offset, params = {}) {
  return api
    .post(
      `${moduleName}/qual/search/byqual`,
      {},
      {
        notify: false,
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
      }
    )
    .then((data) =>
      (data.objectList || []).map((s) =>
        transformSearch(transformModuleName(moduleName), s)
      )
    )
}

export function removeSearchApi(moduleName, id) {
  return api.delete(`${moduleName}/qual/${id}`, {
    message: __t('removed_successfully', { resource: __t('search') }),
  })
}
