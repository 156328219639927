import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('mspConfig')

export const MspConfigComputed = {
  ...mapGetters({
    enableMsp: 'enableMsp',
  }),
}

export const MspConfigMethods = mapActions({
  fetchMspConfigs: 'fetch',
})
