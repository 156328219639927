import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('source')

export const SourceComputed = {
  ...mapGetters(['sourceOptions']),
}

export const SourceMethods = {
  ...mapActions({
    fetchSource: 'fetch',
  }),
}
