import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters: ssoConfigGetters,
  mapActions: ssoConfigActions,
} = createNamespacedHelpers('ssoConfig')

export const SsoConfigComputed = {
  ...ssoConfigGetters(['ssoConfig']),
}

export const SsoConfigActions = {
  ...ssoConfigActions({
    fetchSsoConfig: 'fetch',
  }),
}
