import { createNamespacedHelpers } from 'vuex'

const { mapState, mapGetters, mapActions } =
  createNamespacedHelpers('preference')

export const PreferenceComputed = {
  ...mapState({
    theme: (state) => state.theme,
  }),
  ...mapGetters([
    'shortcutEnabled',
    'showUiNotification',
    'webNotificationPrefs',
    'pageSize',
    'defaultSearchId',
    'defaultLayout',
    'defaultListingColumns',
    'defaultListingColumnsForSupportPortal',
    'defaultKanbanStatusColumns',
    'tenantPrefrences',
    'getSingleTenantPrefrence',
    'myAllowedModules',
    'maxAllowedFileSize',
    'chatEnabled',
    'contactOpsEnabled',
    'theme',
    'allowedFileExtensions',
    'isSetupCompleted',
    'dashboardPreferenceForUser',
    'favouriteItems',
    'attachmentPassword',
    'allowConversationSaveInDraft',
    'thresholdLeaveBalance',
    'allowUserToSelectFromEmail',
    'allowUserToReopenClosedTasks',
    'allowUserToDeleteTasks',
    'autoRefreshIntervalTime',
  ]),
  ...mapGetters({
    userPreferences: 'preferences',
    preferenceLoading: 'loading',
  }),
}

export const PreferenceMethods = mapActions({
  updatePreference: 'updatePreference',
  updateModulePreference: 'updateModulePreference',
  fetchPreferences: 'fetch',
  fetchTenantPrefrences: 'fetchTenantPrefrence',
})
