import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('department')
const {
  mapGetters: departmentConfigGetters,
  mapActions: departmentConfigActions,
} = createNamespacedHelpers('department/departmentConfig')

export const DepartmentComputed = {
  ...mapGetters(['departmentOptions']),
  ...departmentConfigGetters({
    departmentConfig: 'departmentConfig',
    requestOnlyLeafNodeSelectable: 'allowedRequestLeafNode',
    problemOnlyLeafNodeSelectable: 'allowedProblemLeafNode',
    changeOnlyLeafNodeSelectable: 'allowedChangeLeafNode',
    releaseOnlyLeafNodeSelectable: 'allowedReleaseLeafNode',
    reportHierarchy: 'reportHierarchy',
  }),
}

export const DepartmentMethods = {
  ...mapActions({
    fetchDepartment: 'fetch',
  }),
  ...departmentConfigActions({
    departmentConfigFetch: 'fetchConfig',
  }),
}
