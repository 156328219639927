import { isLoggedIn } from '@utils/auth'
import { fetchSourceApi } from './api'
export const state = {
  sources: [],
}

export const getters = {
  sources(state) {
    return state.sources
  },
  sourceOptions(state) {
    const sources = state.sources
    return sources.map((p) => ({
      key: p.id,
      id: p.id,
      value: p.id,
      text: p.name,
      label: p.name,
      systemName: p.systemName,
      archived: p.archived,
    }))
  },
}

export const mutations = {
  SET_SOURCES(state, data) {
    state.sources = data
  },
}

export const actions = {
  init({ dispatch }) {
    if (isLoggedIn()) {
      dispatch('fetch')
    }
  },

  fetch({ commit }, params) {
    return fetchSourceApi({ ...(params || {}), archived: true }).then(
      (data) => {
        commit('SET_SOURCES', data)
        return data
      }
    )
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_SOURCES', [])
  },
}
