import { getReasonTypeApi } from './api'

export const state = {
  reasonTypes: [],
  isReasonTypeLoaded: false,
}

export const getters = {
  reasonTypeOptions(state) {
    return state.reasonTypes
  },
  isReasonTypeLoaded(state) {
    return state.isReasonTypeLoaded
  },
}

export const mutations = {
  SET_REASON_TYPE(state, payload) {
    state.reasonTypes = payload
    state.isReasonTypeLoaded = true
  },
  SET_LOADING(state, payload) {
    state.isReasonTypeLoaded = payload
  },
}

export const actions = {
  fetch({ commit }) {
    getReasonTypeApi(true).then((data) =>
      commit('SET_REASON_TYPE', Object.freeze(data))
    )
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_REASON_TYPE', [])
    commit('SET_LOADING', false)
  },
}
