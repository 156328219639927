import { getReleaseTypeApi } from './api'

export const state = {
  releaseTypes: [],
  isReleaseTypeLoaded: false,
}

export const getters = {
  releaseTypeOptions(state) {
    return state.releaseTypes
  },
  isReleaseTypeLoaded(state) {
    return state.isReleaseTypeLoaded
  },
}

export const mutations = {
  SET_RELEASE_TYPE(state, payload) {
    state.releaseTypes = payload
    state.isReleaseTypeLoaded = true
  },
  SET_LOADING(state, payload) {
    state.isReleaseTypeLoaded = payload
  },
}

export const actions = {
  init({ dispatch }) {},

  fetch({ commit }) {
    getReleaseTypeApi(true).then((data) =>
      commit('SET_RELEASE_TYPE', Object.freeze(data))
    )
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_RELEASE_TYPE', [])
    commit('SET_LOADING', false)
  },
}
