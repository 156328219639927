import api from '@api'
import SortBy from 'lodash/sortBy'
import { transformSource } from '@data/source'
import { isLoggedIn } from '@utils/auth'

export function fetchSourceApi(params = {}) {
  return api
    .get(`${!isLoggedIn() ? '/public' : ''}/source/list`, {
      params: {
        ...params,
      },
      notify: false,
    })
    .then((data) =>
      SortBy(data.objectList || [], 'objOrder').map(transformSource)
    )
}

export function getSourceApi(params = {}) {
  return api
    .post(
      `${!isLoggedIn() ? '/public' : ''}/source/search/byqual`,
      {},
      {
        params: {
          offset: 0,
          size: 1000,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) =>
      SortBy(data.objectList || [], 'objOrder').map(transformSource)
    )
}
