import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('guidedTour')

export const GuidedTourComputed = {
  ...mapGetters({
    guidedTour: 'guidedTour',
    setupCompleted: 'setupCompleted',
    setupCompletedSteps: 'setupCompletedSteps',
    setupCompletedSections: 'setupCompletedSections',
    setupCompletedGuides: 'setupCompletedGuides',
  }),
}

export const GuidedTourMethods = mapActions({
  updateGuidedTour: 'updateGuidedTour',
  fetchGuidedTour: 'fetch',
})
